<template>
  <h3>Offers</h3>
</template>

<script>

export default {
  name: "Offers",
};
</script>
